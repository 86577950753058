import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'

function Timeline() {
  return (
    <div id="career">
      <div className="items-container">
        <h1>Career History</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid  white' }}
            date="2023 - present"
            iconStyle={{ background: '#5b86c4', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Consultant</h3>
            <h4 className="vertical-timeline-element-subtitle">Wizards of the Gracht</h4>
            <p>
              I've had the privilege of collaborating with a diverse range of clients across various industries, providing expertise in Platform Development, DataOps Engineering, and Cloud Architecture.<br></br><br></br> If you're interested in learning more about my work or exploring how we can collaborate, feel free to reach out to me.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2022 - 2023"
            iconStyle={{ background: '#5b86c4', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Senior Advisor Advanced Analytics</h3>
            <h4 className="vertical-timeline-element-subtitle">Hugo Boss (via Metyis)</h4>
            <p>
              Advice and requirement engineering Data Platform architecture and implementation on Azure Cloud.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2022- 2023"
            iconStyle={{ background: '#5b86c4', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">CloudOps Lead</h3>
            <h4 className="vertical-timeline-element-subtitle">Metyis</h4>
            <p>
              Cloud Operations Lead for multiple offices. Implementation of cloud infrastructure, Cloud/DevOps Developers Manager, ISO27001, development platforms, CI/CD pipelines and more.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021 - 2022"
            iconStyle={{ background: '#5b86c4', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Cloud Architect & DataOps Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle">Nutreco (via AMIS)</h4>
            <p>
              Data Platform architecture and implementation on Azure Cloud.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2018 - 2022"
            iconStyle={{ background: '#5b86c4', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Senior Consultant</h3>
            <h4 className="vertical-timeline-element-subtitle">AMIS</h4>
            <p>
              Development, Automation, System Integration, Networking, Security, Workshops, Data Platforms, Data Governance and more.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;