import React, { useEffect, useRef } from 'react';
import NET from 'vanta/dist/vanta.net.min';
import * as THREE from 'three';
import LinkedInIcon from '@mui/icons-material/LinkedIn'; 


const VantaBackground: React.FC = () => {
  const vantaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const vantaEffect = NET({
      el: vantaRef.current,
      THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      scale: 1.0,
      minHeight: 200.00,
      minWidth: 200.00,
      spacing: 13.0,
      color: 0x707eb,
      backgroundColor: 0x7d7df0,
      points: 8.00,
      maxDistance: 10.00
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <div
    ref={vantaRef}
    style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
      <div className="about-section">
        <div className="image-wrapper">
          <img src={require('../assets/images/sam-crop.png')} alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://www.linkedin.com/in/sam-vruggink-440448127/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
          <h1>Sam Vruggink</h1>
          <p>Cloud & DataOps Engineer | Solutions Architect | 🧙</p>
            <p style={{ fontSize: '0.8em' }}>Specialized in Data Platforms, integration and implementation.</p>
            <div className="mobile_social_icons" style={{ position: 'absolute', zIndex: 10 }}>
            <a href="https://www.linkedin.com/in/sam-vruggink-440448127/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default VantaBackground;