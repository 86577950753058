import React from "react";
import asolutionarchitect from '../assets/images/azure-solutions-architect-expert-600x600.png';
import adevassociate from '../assets/images/azure-developer-associate-600x600.png';
import adataengineer from '../assets/images/de.png';
import adevops from '../assets/images/devops.png';
import eiso from '../assets/images/exin_badge_modulefoundation_informationsm_iso-1024x1024.png';
import aiot from '../assets/images/iot.png';
import dlakehouse from '../assets/images/lakehouse-fundamentals.png';
import terraform from '../assets/images/terraform.png';
import eagile from '../assets/images/vanharen_badge_agile_foundation_new_png_4.png';
import anglia from '../assets/images/anglia.png';
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="certifications">
        <h1>My Certifications</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://www.credly.com/badges/d7c81fdd-cc85-45a4-93f3-3b8abb100e2c/linked_in_profile" target="_blank" rel="noreferrer"><img src={asolutionarchitect} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Microsoft Certified: Azure Solutions Architect Expert</h3>
            </div>
            <div className="project">
                <a href="https://www.credly.com/badges/a39339f0-dbf2-46e0-b4b9-3ddfb8e8b1a6" target="_blank" rel="noreferrer"><img src={adevops} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Microsoft Certified: DevOps Engineer Expert</h3>
    
            </div>
            <div className="project">
                <a href="https://www.credly.com/badges/8f9c8c5c-67ef-4896-8493-98da782edc59" target="_blank" rel="noreferrer"><img src={adataengineer} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Microsoft Certified: Azure Data Engineer Associate</h3>
            </div>
            <div className="project">
                <a href="https://www.credly.com/badges/17a01152-a142-4894-9830-74e1474970b2" target="_blank" rel="noreferrer"><img src={aiot} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Microsoft Certified: Azure IoT Developer Specialty</h3>
            </div>
            <div className="project">
                <a href="https://www.credly.com/badges/820e4ff7-9c61-48c8-8d9c-49a89584994a" target="_blank" rel="noreferrer"><img src={terraform} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>HashiCorp Certified: Terraform Associate (002)</h3>
            </div>
            <div className="project">
                <a href="https://www.credly.com/badges/7ff276ab-e454-4f93-805b-cc9bba3f433c" target="_blank" rel="noreferrer"><img src={adevassociate} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://www.byuh.edu/covid-19-case-management" target="_blank" rel="noreferrer"><h3>Microsoft Certified: Azure Developer Associate</h3></a>
            </div>
            <div className="project">
                <a href="https://credentials.databricks.com/c1b33798-dd0e-44a1-b485-16b38bbe74ab" target="_blank" rel="noreferrer"><img src={dlakehouse} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Databricks Lakehouse Fundamentals</h3>
            </div>
            <div className="project">
                <a href="https://www.linkedin.com/in/sam-vruggink-440448127/details/certifications/" target="_blank" rel="noreferrer"><img src={eiso} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Information Security Foundation based on ISO IEC 27001</h3>
            </div>
            <div className="project">
                <a href="https://www.linkedin.com/in/sam-vruggink-440448127/details/certifications/" target="_blank" rel="noreferrer"><img src={eagile} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Agile Foundation Certificate</h3>
            </div>
            <div className="project">
                <a href="https://www.linkedin.com/in/sam-vruggink-440448127/details/certifications/" target="_blank" rel="noreferrer"><img src={anglia} className="zoom" alt="thumbnail" width="100%"/></a>
                <h3>Anglia C1</h3>
            </div>
        </div>
    </div>
    );
}

export default Project;