import '../assets/styles/Main.scss';
import VantaBackground from './Vanta'; // Import the VantaBackground component


function Main() {


  return (
    <div className="container" id="kek">
      <VantaBackground />
    </div>
  );
}

export default Main;