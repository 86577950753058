import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Footer.scss'

function Footer() {
  return (
    <footer>
      <div>
        <a href="https://www.linkedin.com/in/sam-vruggink-440448127/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
      </div>
      <p>Wizards of the Gracht 2025 🧙</p>
    </footer>
  );
}

export default Footer;