import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';
import { faServer } from "@fortawesome/free-solid-svg-icons";

const labelsFirst = [
    "PySpark",
    "Pandas",
    "Python",
    "Pytest",
    "T-SQL",
    "Databricks",
    "Synapse",
    "Data Factory",
    "FastAPI",
    "Delta Lakes",
    "Fabric",
    "Kubernetes",
    "And much more.."
];

const labelsSecond = [
    "Terraform",
    "YAML",
    "Git",
    "Pipelines",
    "Azure DevOps",
    "Gitlab",
    "Atlassian Suite",
    "Docker",
    "AWS",
    "Azure",
    "GCP",
    "Orchestration",
    "Hetzner",
    "Linux/cmd",
    "Windows/pwsh",
    "And much more.."
];

const labelsThird = [
    "Architectural Assessment and Guidance",
    "Data Platform Implementation",
    "Data Platform Optimization",
    "Technical Recruitment Interviews",
    "Teaching and Workshops",
    "Development and Code Reviews",
    "And much more.."
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faServer} size="3x"/>
                    <h3>Data Platforms</h3>
                    <p>With expertise in building and maintaining modern cloud data platforms, I design scalable, secure, and automated solutions. My approach emphasizes seamless integration, operational efficiency, and future-ready architecture tailored to business needs.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>DevOps & Automation</h3>
                    <p>I specialize in integrating Infrastructure as Code (IaC) and streamlining workflows with CI/CD pipelines. My goal is to ensure scalable, resilient, and efficient operations by leveraging automation at every stage.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faPython} size="3x"/>
                    <h3>Consulting</h3>
                    <p>I tackle complex challenges, from architecting innovative solutions to guiding teams through difficult projects. My consulting includes system design, mentorship, technical recruitment processes, and providing actionable insights for scaling operations. Simplifying the complexity of solution and systems is what truly drives me.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Services:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;